import { template as template_da1b31eba9c64ce29bf5552b32bf13cf } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import CdnImg from "discourse/components/cdn-img";
import { getURLWithCDN } from "discourse-common/lib/get-url";
export default class LightDarkImg extends Component {
    @service
    session;
    get isDarkImageAvailable() {
        return (this.args.lightImg?.url && // the light image must be present
        this.args.darkImg?.url && (this.session.defaultColorSchemeIsDark || this.session.darkModeAvailable));
    }
    get defaultImg() {
        // use dark logo by default in edge case
        // when scheme is dark and dark logo is present
        if (this.session.defaultColorSchemeIsDark && this.args.darkImg) {
            return this.args.darkImg;
        }
        return this.args.lightImg;
    }
    get darkImgCdnSrc() {
        return getURLWithCDN(this.args.darkImg.url);
    }
    static{
        template_da1b31eba9c64ce29bf5552b32bf13cf(`
    {{#if this.isDarkImageAvailable}}
      <picture>
        <source
          srcset={{this.darkImgCdnSrc}}
          width={{@darkImg.width}}
          height={{@darkImg.height}}
          media="(prefers-color-scheme: dark)"
        />
        <CdnImg
          ...attributes
          @src={{this.defaultImg.url}}
          @width={{this.defaultImg.width}}
          @height={{this.defaultImg.height}}
        />
      </picture>
    {{else if @lightImg.url}}
      <CdnImg
        ...attributes
        @src={{@lightImg.url}}
        @width={{@lightImg.width}}
        @height={{@lightImg.height}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}

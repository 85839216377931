import { template as template_7bf67dcc73644473a733cb0857c53880 } from "@ember/template-compiler";
const WelcomeHeader = template_7bf67dcc73644473a733cb0857c53880(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

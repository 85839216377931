import { template as template_0fcee421699345b9803eb714551deffe } from "@ember/template-compiler";
const FKControlMenuContainer = template_0fcee421699345b9803eb714551deffe(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_ac84130e4cf94ba1a36a8e9ec4d08258 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_ac84130e4cf94ba1a36a8e9ec4d08258(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
